<template>
  <div class="brand_container">
    <div class="content">
      <div class="content-title">领券中心</div>
      <div class="coupon-box">
        <div v-for="(item, index) in list" :key="index" class="item-box" @mouseenter="activeInd = index" @mouseleave="activeInd = -1" @click="getCouponFn(item, index)">
          <div class="mask-box" v-if="activeInd == index && item.status == 101">立即领取</div>
          <div class="dot-top"></div>
          <div class="dot-bottom"></div>
          <div class="coupon-left">
            <div class="left-top">
              <div class="money-text">
                ￥<span class="money-style">{{ item.money }}</span>
              </div>
              <!-- <div>{{ item.title }}</div> -->
            </div>
            <div class="left-get">{{ item.status == 101 ? "立即领取" : "暂时无法领取" }}</div>
          </div>
          <div class="coupon-right">
            <div class="right-top">
              <div class="right-title">{{ item.title }}</div>
              <div>{{ item.desc }}</div>
              <div>{{ item.status_str }}</div>
            </div>
            <div class="right-buttom">{{ item.use_time }}</div>
          </div>
        </div>
        <div class="empty">
          <el-empty v-if="!list.length" :image-size="200"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      activeInd: -1,
      info: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api("home.getCoupon").then((res) => {
        this.list = res.data;
      });
    },
    getCouponFn(data, ind) {
      if (data.status == 101) {
        this.info = data;
        this.activeInd = ind;
        this.$api("home.getCouponItem", { id: data.id }).then((res) => {
          if (res.code == 200) {
            this.getData();
            this.$message.success("领取成功");
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.brand_container {
  // background-size: cover;
  padding-bottom: 20px;
}

.content {
  padding-bottom: 20px;
}
.content-title {
  width: 100%;
  text-align: center;
  font-size: 30px;
  padding: 40px 0;
  color: #fa6506;
  border-bottom: 2px solid #ff9800;
  margin-bottom: 40px;
}
.coupon-box {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // height: 200px;
  // background-color: blueviolet;
  .item-box {
    position: relative;
    width: 560px;
    height: 200px;
    margin-bottom: 20px;
    background-color: #e6e6e6;
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    .dot-top {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: -10px;
      left: 150px;
    }
    .dot-bottom {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      bottom: -10px;
      left: 150px;
    }
    .mask-box {
      width: 560px;
      height: 200px;
      line-height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      color: #fff;
      font-size: 20px;
      background-color: rgba(0, 0, 0, 0.3);
    }
    // -webkit-mask: radial-gradient(circle at 10px, #0000 10px, #fdfbea 0);
    // -webkit-mask-position: -10px;
    // -webkit-mask-size: 100% 30px;
    &:hover {
      cursor: pointer;
      box-shadow: inset 0px 0px 10px 0px rgb(17, 17, 17) !important;
    }
    .coupon-left {
      width: 160px;
      height: 100%;
      .left-top {
        width: 100%;
        height: 150px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: linear-gradient(to right, #f98942, #fa6506);
        .money-text {
          font-size: 14px;
          font-weight: bold;
          color: #fff;
          .money-style {
            font-size: 34px;
          }
        }
      }
      .left-get {
        width: 100%;
        height: 50px;
        text-align: center;
        line-height: 50px;
        background-color: #e6e6e6;
      }
    }
    .coupon-right {
      // width: calc(100% - 160px);
      width: 400px;
      height: 100%;

      // padding: 20px;
      background-color: #fff;
      .right-top {
        // width: 100%;
        width: 380px;
        height: 130px;
        padding-left: 20px;
        padding-top: 20px;
        .right-title {
          font-size: 24px;
          // margin-top: 20px;
          color: #333;
          // margin-left: 20px;
          margin-bottom: 20px;
        }
      }
      .right-buttom {
        // width: 100%;
        width: 380px;
        height: 50px;
        padding-left: 20px;
        color: #7e7575;
        font-size: 16px;
        background-color: #e6e6e6;
        // text-align: center;
        line-height: 50px;
      }
    }
  }
  .empty {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
